import svKendoMessages from './kendo/sv.json';

export const svProductMessages = {
  ...svKendoMessages,
  construo: {
    global: {
      loginTitle: 'Logga in',
      logoutTitle: 'Logga ut',
      loading: 'Laddar ...',
      successfullyLoggedOut: 'Du är utloggad',
      loggedOutHeading: 'Tack för ditt besök',
      loggedOutSubHeading:
        'Du har loggat ut. Om du använder en offentlig dator rekommenderar vi att du stänger webbläsaren.',
      logBackIn: 'Logga in igen',
      download: 'Ladda ned',
      previewNotSupported:
        'Förhandsgranskning är inte tillgänglig på denna enhet. Ladda ner filen för att se den.',
      previewNotAvailable:
        'Den här filen kan inte förhandsgranskas. Ladda ner filen för att se den.',
      processingLogin:
        'Vi undersöker din webbläsare för att logga in dig. Detta kan ta ett ögonblick.',
      processingLoginCallback: 'Du loggas in. Vänligen vänta.',
      processingLogout:
        'Du loggas av på ett säkert sätt. Vänligen håll detta fönster öppet.',
      processingLogoutCallback: 'Du loggas ut.',
      gotoLoginPage: 'Klicka här för att logga in',
      page404Title: '404 Error - Vi kan inte hitta den sidan du letar efter',
      page404Text:
        'Vi kan inte hitta sidan med URL {0}. Gå tillbaka till hemsidan och försök igen.',
      resource404Title: '{0} inte hittad',
      resource404Text:
        'Vi kunde inne hitta {0} du försöker att öppna. Den existerar kanske inte, eller du har kanske inte längre tillgång till den. Om du öppnade denna sidan genom att skriva in en URL i din nätläsare, vänligen kontrollera att du har skrivit korrekt adress.',
      resourceType: 'Sidan',
      submissionType: 'Schemat',
      workflowType: 'Arbetsflöde',
      taskType: 'Uppgiften',
      conversationType: 'Meddelandet',
      documentType: 'Dokumentet',
      id: 'ID',
      resource: 'Ressurs',
      type: 'Typ',
      invalid: 'Ogiltigt ID-format',
    },
    header: {
      logoAlt: 'Loga av {0}',
      menuBtnLabel: 'Meny',
    },
    desktopNavigation: {
      link1Label: '',
      link1URL: '',
      link2Label: '',
      link2URL: '',
      link3Label: '',
      link3URL: '',
      link4Label: '',
      link4URL: '',
      link5Label: '',
      link5URL: '',
    },
    footer: {
      text: '© {0} {1}',
      link1Label: 'Länk 1',
      link1URL: 'https://google.com/',
      link2Label: 'Länk 2',
      link2URL: 'https://google.com/',
      link3Label: '',
      link3URL: '',
    },
    menu: {
      close: 'Stäng',
      home: 'Hem',
      portfolios: 'Portföljer',
      documents: 'Dokument',
      publications: 'Publikationer',
      messages: 'Meddelanden',
      inbox: 'Inkorg',
      login: 'Logga in',
      logout: 'Logga ut',
      languageSwitcherLabel: 'Språk',
      english: 'English',
      norwegian: 'Norsk',
      finnish: 'Suomi',
      swedish: 'Svenska',
      accountNameTagline: '{0} för',
      switchAccount: 'Byt enhet',
    },
    selectAccount: {
      title: 'Välj enhet',
      heading: 'Välkommen, {0}',
      subheading: 'Välj företaget / kunden som du vill se information om',
    },
    homepage: {
      publicHeading: 'Välkommen till vår portal',
      publicSubHeading: 'Vänligen logga in för att fortsätta',
      login: 'Logga in',
      actionRequired: 'Dina uppgifter:',
      latestDocuments: 'Sista dokument',
      goToDocuments: 'Gå till alla dokument',
      signingCompleted: 'Signaturen är fullförd',
      signingCompletedText: 'Tack! Du har fullfört signeringen.',
      signingUncompleted: 'Signatur misslyckades',
      signingUncompletedText:
        'Dokumentet blev inte signerat. Vänligen försök igen senare.',
      portfoliosSection: {
        title: 'Portföljer',
        totalMarketValue: 'Marknadsvärde',
        periodEndMarketValue: 'Marknadsvärde',
        mtdTotalGain: 'Avkastning hittills denna månad',
        ytdTotalGain: 'Avkastning hittills i år',
        sinceInceptionTotalGain: 'Avkastning sedan start {0}',
        seePortfolio: 'Se hela portföljen',
      },
      publicationsSection: {
        title: 'Publikationer',
        goToPublications: 'Visa alla publikationer',
        loadingPublicationPreview: 'Laddar publikationer...',
        noPublicationsMessage:
          'Det finns inga publikationer att visa för tillfället.',
        categoryName_1: 'Kategori 1',
        categoryName_2: 'Kategori 2',
        categoryName_3: 'Kategori 3',
        categoryName_4: 'Kategori 4',
      },
    },
    signatureRequests: {
      noSignatureName: 'Din signeringsförfrågan',
      signBefore: 'Signatur krävs innan',
      redirectToESignLoadingText: 'Vi sänder dig vidare till dokumentet ...',
      userMissingNinAndBankId:
        'Vi kunde dessvärre inte hämta ut din information från BankID på nu. Vänligen försök igen senare. Om problemet fortsätter, vänligen ta kontakt för assistans.',
      userMissingNinAndLocal:
        'e-signeringsförfrågan är enbart tillgänglig när du har loggat in med digital identitet. Vänligen logga in med BankID för att se din signaturförfrågan. Om du har mottagit en e-post från oss med angående en öppen e-signeringsförfrågan och du inte har BankID, vänligen ta kontakt.',
    },
    uploadRequests: {
      uploadBefore: 'Ladda upp innan',
      upload: 'Ladda upp',
      docUpload: 'Uppladdning',
      uploadingDoc: 'Ditt dokument uppladdas',
      requireDoc: 'Vi behöver följande dokument från dig:',
      requireAttachments:
        'Vänligen ladda upp efterfrågad dokumentation här. Du kan ladda upp max 15 dokument per uppladdning.',
      fileRestriction:
        'På mobil kan du välja en fil eller ta en bild. Enbart PDF-dokument och bilder (.jpg, .jpeg, .png) tillåtet. Maximal filstorlek för uppladdning är 10 MB.',
      noDocumentName: 'Din dokumentförfrågan',
      noCommentToClient: '',
      uploadSuccessful: 'Tack, vi har mottagit ditt dokument',
      uploadFailed: 'Det har dessvärre inträffat en fel med din uppladdning:',
    },
    documents: {
      title: 'Dokument',
      publications: 'Publikationer',
      documentName: 'Dokumentnamn',
      contacts: 'Kontakt',
      category: 'Kategori',
      documentType: 'Dokumenttyp',
      documentDate: 'Datum',
      nameAsc: 'Dokumentnamn (A-Z)',
      nameDesc: 'Dokumentnamn (Z-A)',
      dateNewestFirst: 'Datum (sista först)',
      dateOldestFirst: 'Datum (äldsta först)',
      noDocumentsMessage: 'Det är för ögonblicket inga dokument att visa.',
      noResultsMessage:
        'Inga resultat samsvarar med sökkriterier eller filterinställningar.',
      selectedToDownload: 'markerat för nedladdning',
      bulkDownloadZipFileName: 'Dokument',
      preparingBulkDownload: 'Vi förbereder dina dokument för nedladdning',
      filters: 'Filter',
      filterByContacts: 'Kontakt',
      filterByCategory: 'Kategori',
      filterByType: 'Dokumenttyp',
      filterByYear: 'År',
      filterByDocumentName: 'Sök med dokumentnamn',
      closeFilters: 'Stäng filter',
      clearFilters: 'Tillbakaställ filter',
      applyFilters: 'Använd filter',
      sortBy: 'Sortera efter',
      loadMore: 'Ladda flera',
      loadingDocumentPreview: 'Dokument laddar...',
    },
    idleTimer: {
      modalTitle: 'Är du fortfarande här?',
      modalMessage:
        'Du har varit inaktiv en stund och blir automatiskt utloggad om ett ögonblick',
      logMeOut: 'Logga ut',
      keepMeSignedIn: 'Fortsätt att vara inloggad',
    },
    forms: {
      alertTest: 'Detta är ett testschema och kan inte skickas in.',
      alertErrorCreatingSigningRequest:
        'Ett fel uppstod under bearbetningen. Vi kommer att kontakta dig med ytterligare information.',
      loaderCreatingSigningRequest: 'Ett ögonblick, du kommer att omdirigeras.',
      loaderSubmittingForm: 'Dina svar skickas in.',
      loaderGeneratingPdf: 'Ett ögonblick, vi behandlar dina uppgifter.',
      processingFormAuthentication: 'Vänligen vänta, sidan laddas',
      processingFormAuthenticationCallback:
        'Vi identifierar dig, tack för att du väntar.',
      formSubmittedHeading: 'Ditt schema har skickats in',
      formSubmittedText: 'Tack, du har skickat in dina svar.',
      formInaccessibleHeading: 'Schema otillgängligt',
      formInaccessibleText:
        'Dette schema är dessvärre inte tillgängligt längre. Kontakta oss för vidare assistans.',
      reload: 'Uppdatera',
      smsSecurityCode: 'Engångskod på SMS',
      inOrderToAccess:
        'För att få tillgång till schema behöver du en engångskod som skickas till ditt mobiltelefonnummer.',
      sendCode: 'Skicka koden',
      sentSixDigitCode: 'Vi har skickat en engångskod på sex siffror till dig.',
      enterBelow: 'Uppge engångskoden under:',
      accessBtnLabel: 'Tillgång',
      didNotReceiveCode: 'Fick du inte koden?',
      requestCode: 'Skicka ny kod',
      waitToRequestCode: 'Vänta 60 sekunder innan du ber om en ny kod.',
      inputValidatorText:
        'Vi har skickat en SMS-säkerhetskod till dig. Vänligen skriv in koden under.',
      fieldHint: 'Skriv SMS-koden här',
      bankIdLoginWarningHeading: 'Logga in för att fortsätta',
      bankIdLoginWarningText:
        'För att få tillgång till det här innehållet måste du logga in med en e-identitet som BankId.',
      tokenExpiredHeading: 'Länken är inte giltig längre',
      tokenExpiredText:
        'Tillgångslänken till det här schemat har utlöpt {0}. Ta kontakt med oss för att få en ny länk.',
      formSealedHeading: 'Låst schema',
      formSealedText:
        'Detta schema är redan inskickat och kan inte ändras längre.',
      pleaseCompleteForm: 'Vänligen slutför schema',
      chooseFormSubmissionTitle: 'Hur vill du fortsätta?',
      chooseFormSubmissionP1Text: 'Denna ansökan har automatiskt sparats.',
      chooseFormSubmissionP2Text:
        'Vill du se den automatiskt sparade versionen eller börja om från början?',
      tempButtonLabel: 'Automatiskt sparat',
      savedButtonLabel: 'Börja om',
      noSubmissionName: 'Ditt kundschema',
      submissionCouldNotBeLoaded:
        'Något gick fel. Schema kunde inte laddas upp.',
      otherUserWorkingOnSubmission:
        'Schema är inte tillgängligt, då det avvänds av någon annan.',
      submissionSavedTitle: 'Din ansökan är sparad',
      submissionSavedText:
        'Din ansökan är sparad, du kan tryggt lämna denna sida och komma tillbaka när du vill fortsätta.',
      saveForLater: 'Spara',
      saveForLaterNote: 'Om du vill fortsätta senare kan du trycka',
      returnHome: 'Gå tillbaka till hemesidan',
      formLockedByUser:
        'Du kan inte uppge information i det här schemat, för någon annan jobbar i det just nu. Vänligen försök att öppna schemat igen om några minuter.',
      formSaved:
        'Dina ändringar har blivit sparade. Du kan stänga din ansökan och fortsätta senare.',
      formNotSaved:
        'Det uppstod dessvärre ett fel när vi sparade dina ändringar. Vänligen försök igen.',
      formSubmitted: 'Tack, vi har tagit emot din leverans.',
      formSubmissionNot: 'Det uppstod dessvärre ett fel och schemat kunde inte',
      locked: 'låsas',
      unlocked: 'låsas upp',
      submitError: 'Det uppstod dessvärre ett fel med schemaleveransen:',
      autoSaveError:
        'Det uppstod dessvärre ett fel med schemats automatiska sparfunktion:',
      noFormData:
        'På grund av saknade data kan formuläret inte visas. Vänligen kontakta oss.',
      showSubmittedData: 'Visa inskickade uppgifter',
      hideSubmittedData: 'Dölj inskickade uppgifter',
      requiredAuthenticationInfoPageHeader: 'Vi återkommer till dig',
      requiredAuthenticationInfoPageText:
        'Vi återkommer till dig inom de närmaste dagarna.',
    },
    workflows: {
      noWorkflowName: 'Din ansökansprocess',
      workflowDescription:
        'Vi behöver ytterligare information från dig för att färdigställa processen. Vänligen fullför uppgifterna under.',
      accessedBy: 'Denna uppgiften är tillgänglig för:',
      noAttachmentsAdded: 'Ingen bilaga har blivit lagt till än.',
      addFirstDocument: 'Bifoga till första dokument',
      addDocuments: 'Bifoga till flera dokument',
      reviewingSubmission:
        'Vi genomgår den mottagna dokumentationen och kontaktar dig vid behov för mer information.',
      completedSubmission: 'Fullfört',
      completeBtnLabel: 'Fullför detta steget',
      noActionAdded: 'Inga öppna uppgifter.',
      completeActionSuccess: 'Tack, steget är fullfört.',
      completeActionError: 'Steget kunde inte fullföras:',
      unreadMessages: 'Oläst meddelande',
      readReceiptsError: 'Systemet kunde inte markera meddelandet som läst:',
      messagesTitle: 'Meddelanden',
      initializationText: 'Skriv meddelande…',
      placeholderText: 'Skriv meddelande…',
      anonymousAuthorName: 'Kundcenter',
      workflowIdNotFound: 'Arbetsflöde-ID-en kunde inte hittas i API-svaret',
    },
    inbox: {
      title: 'Inkorg',
      newMessage: 'Nytt meddelande',
      yourPendingActivities: 'Dina väntande aktiviteter',
      noPendingActivities: 'Du har inga väntande aktiviteter.',
      yourMessages: 'Dina meddelanden',
      noMessages: 'Du har inga meddelanden.',
      unreadMessage: 'Oläst meddelande',
      signBy: 'Signatur krävs innan',
      uploadBy: 'Ladda upp innan',
      signatureRequired: 'Din signatur krävs',
      documentRequired: 'Vi kräver ett dokument från dig',
      completeForm: 'Fyll i detta formulär',
      ongoingApplicationProcess: 'Pågående ansökningsprocess',
      uploadActionDetailsTitle: 'Uppladdning av dokument',
      uploadActionDetailsText: 'Vi behöver följande dokument från dig:',
      uploadActionDetailsNote:
        'På mobil kan du välja en fil eller ta en bild. Enbart PDF-dokument och bilder (.jpg, .jpeg, .png) tillåtet. Maximal filstorlek för uppladdning är 10 MB.',
      uploadActionDetailsButtonLabel: 'Ladda upp dokumentet',
      backToInbox: 'Tillbaka till inboxen',
      createNewMessage: 'Nytt meddelande',
      send: 'Skicka',
      messageTopic: 'Välj ett tema…',
      messageSubject: 'Skriv ämne…',
      messageText: 'Skriv meddelande…',
      couldNotFoundContent: 'Vi kunde inte hitta den här sidans innehåll.',
      taskDoesNotExist:
        'Uppgiften existerar inte eller är inte längre tillgänglig för dig.',
      canSeeHistory: 'Du kan se meddelandehistoriken för denna uppgiften',
      conversationLinkLabel: 'här',
      warningUnavailableTask:
        'Du kan inte längre delta i den här samtalen. För att kontakta oss, vänligen starta ett nytt meddelande från inboxen.',
      warningConversationStatus:
        'Meddelandena har blivit arkiverade och du kan inte skriva nye meddelanden längre. För att kontakta oss, vänligen starta ett nytt meddelande från inboxen.',
    },
    portfolios: {
      title: 'Portföljer',
      tabAllocationTitle: 'Tilldelning',
      tabHoldingsTitle: 'Innehav',
      tabPerformanceTitle: 'Avkastning',
      tabTransactionsTitle: 'Transaktioner',
      tabIndexTitle: 'Index',
      tableLinkLabel: 'Öppna',
      tableAllocationTitle: 'Tilldelning',
      tableHoldingsTitle: 'Innehav',
      tablePerformanceTitle: 'Avkastning',
      tablePerformanceTwoTitle: 'Avkastning (%)',
      tableTransactionsTitle: 'Transaktioner',
      tableIndexTitle: 'Index',
      tableAllocationFooterRowTitle: 'Totalt',
      tableHoldingsFooterRowTitle: 'Totalt',
      tablePerformanceFooterRowTitle: 'Totalt',
      tablePerformanceTwoFooterRowTitle: 'Totalt',
      graphPerformanceDatepickerStartLabel: 'Start',
      graphPerformanceDatepickerEndLabel: 'Slut',
      graphPerformanceValueAxisTitle: 'Avkastning (Indexerad)',
      graphDatePickerInvalidStartDate: 'Ogiltigt startdatum',
      graphDatePickerInvalidEndDate: 'Ogiltigt slutdatum',
      loadMore: 'Ladda flera',
      lastUpdatedTxt:
        'Senast uppdaterad den {0:d} med senaste tillgängliga kurser den {1:d}.',
      lackOfUpdatesTxt:
        'Bristen på uppdateringar beror på transaktioner som pågår.',
      disclaimerText: 'Vi reserverar oss för eventuella fel i rapporten.',
      userWithoutPortfoliosText:
        'Vi kan inte hitta några aktiva portföljer kopplade till din profil för tillfället. Portföljrapporter kan vara tillfälligt otillgängliga under datauppdateringar. Vänligen försök igen senare eller kontakta oss för ytterligare hjälp.',
      accountWithoutPortfoliosText:
        'Vi kan inte hitta några aktiva portföljer kopplade till denna enhet för tillfället. Portföljrapporter kan vara tillfälligt otillgängliga under datauppdateringar. Vänligen försök igen senare eller kontakta oss för ytterligare hjälp.',
      userPortfoliosError:
        'Denna portfölj är tillfälligt otillgänglig. Vänligen försök igen senare.',
      holdingsError: 'Data kunde inte laddas. Vänligen försök igen senare.',
      timeSeriesError:
        'Visualiseringen kunde inte laddas. Vänligen försök igen senare.',
      transactionsError: 'Data kunde inte laddas. Vänligen försök igen senare.',
      indicesError: 'Data kunde inte laddas. Vänligen försök igen senare.',
      tableFilterLabels: {
        transactionDate: 'Filtrera efter år',
        expectedSettlementDate: 'Filtrera efter år',
        type: 'Filtrera efter typ',
      },
      allocationTableColumns: {
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Rapportnivå',
        sortOrder: 'Sorteringsordning',
        isIndex: 'Är index',
        currentHolding: 'Nuvarande innehav',
        contactGuid: 'Kontakt-GUID',
        accountId: 'Kontonummer',
        portfolioId: 'Portfölj-ID',
        portfolioName: 'Portföljnamn',
        title: 'Tillgångsklass',
        securitySymbol: 'Symbol',
        isin: 'ISIN',
        sfdr: 'SFDR',
        portfolioBaseTypeCode: 'Bas-kod',
        securityTypeCode: 'Säkerhets-kod',
        allocation: 'Fördelning',
        costBasis: 'Kostnadsgrund',
        fifoCost: 'FIFO-kostnad',
        indexWeight: 'Strategisk vikt',
        lowerLimit: 'Nedre gräns',
        upperLimit: 'Övre gräns',
        movement: 'Insättning/uttag',
        quantity: 'Antal',
        unrealizedGainLoss: 'Orealiserad vinst/förlust',
        itdAnnualizedTwr: 'TWR sedan start',
        itdFromDate: 'Startdatum',
        oneYearTwr: '1 års TWR',
        oneYearTotalGain: '1 års totalvinst',
        oneYearFromDate: '1 års datum',
        threeYearTotalGain: '3 års totalvinst',
        threeYearAnnualizedTwr: '3 års TWR',
        threeYearFromDate: '3 års datum',
        fiveYearAnnualizedTwr: '5 års TWR',
        fiveYearTotalGain: '5 års totalvinst',
        fiveYearFromDate: '5 års datum',
        ytdTotalGain: 'Totalvinst YTD',
        ytdTwr: 'TWR YTD',
        ytdFromDate: 'YTD-datum',
        mtdTotalGain: 'Avkastning denna månad',
        mtdTwr: 'TWR MTD',
        mtdFromDate: 'MTD-datum',
        periodEndMarketValue: 'Marknadsvärde',
        periodEndRelativeMarketValue: 'Relativt MKV',
        periodEndTotalGain: 'Totalvinst',
        periodEndWeight: 'Portföljevikt',
        periodEndDate: 'Slutdatum',
        sinceInceptionTotalGain: 'Totalvinst sedan start',
        sinceInceptionAnnualizedTwr: 'Årlig TWR sedan start',
        sinceInceptionDate: 'Startdatum',
        thruDate: 'Genom datum',
        date1: 'Date 1',
        date2: 'Date 2',
        date3: 'Date 3',
        date4: 'Date 4',
        date5: 'Date 5',
        double1: 'Double 1',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'double 4',
        double5: 'Double 5',
        int1: 'Int 1',
        int2: 'Int 2',
        int3: 'Int 3',
        int4: 'Int 4',
        int5: 'Int 5',
        string1: 'Frihetsgrader',
        string2: 'String 2',
        string3: 'String 3',
        string4: 'String 4',
        string5: 'Dok.',
      },
      holdingsTableColumns: {
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Rapportnivå',
        sortOrder: 'Sorteringsordning',
        isIndex: 'Är index',
        currentHolding: 'Nuvarande innehav',
        contactGuid: 'Kontakt-GUID',
        accountId: 'Kontonummer',
        portfolioId: 'Portfölj-ID',
        portfolioName: 'Portföljnamn',
        title: 'Tillgångsklass',
        securitySymbol: 'Symbol',
        isin: 'ISIN',
        sfdr: 'SFDR',
        portfolioBaseTypeCode: 'Bas-kod',
        securityTypeCode: 'Säkerhets-kod',
        allocation: 'Fördelning',
        costBasis: 'Kostnadsgrund',
        fifoCost: 'FIFO-kostnad',
        indexWeight: 'Strategisk vikt',
        lowerLimit: 'Nedre gräns',
        upperLimit: 'Övre gräns',
        movement: 'Insättning/uttag',
        quantity: 'Antal',
        unrealizedGainLoss: 'Orealiserad vinst/förlust',
        itdAnnualizedTwr: 'TWR sedan start',
        itdFromDate: 'Startdatum',
        oneYearTwr: '1 års TWR',
        oneYearTotalGain: '1 års totalvinst',
        oneYearFromDate: '1 års datum',
        threeYearTotalGain: '3 års totalvinst',
        threeYearAnnualizedTwr: '3 års TWR',
        threeYearFromDate: '3 års datum',
        fiveYearAnnualizedTwr: '5 års TWR',
        fiveYearTotalGain: '5 års totalvinst',
        fiveYearFromDate: '5 års datum',
        ytdTotalGain: 'Totalvinst YTD',
        ytdTwr: 'TWR YTD',
        ytdFromDate: 'YTD-datum',
        mtdTotalGain: 'Avkastning denna månad',
        mtdTwr: 'TWR MTD',
        mtdFromDate: 'MTD-datum',
        periodEndMarketValue: 'Marknadsvärde',
        periodEndRelativeMarketValue: 'Relativt MKV',
        periodEndTotalGain: 'Totalvinst',
        periodEndWeight: 'Portföljevikt',
        periodEndDate: 'Slutdatum',
        sinceInceptionTotalGain: 'Totalvinst sedan start',
        sinceInceptionAnnualizedTwr: 'Årlig TWR sedan start',
        sinceInceptionDate: 'Startdatum',
        thruDate: 'Genom datum',
        date1: 'Siste kursdato',
        date2: 'Investert dato',
        date3: 'Date 3',
        date4: 'Date 4',
        date5: 'Date 5',
        double1: 'Markedskurs',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'double 4',
        double5: 'Double 5',
        int1: 'Int 1',
        int2: 'Int 2',
        int3: 'Int 3',
        int4: 'Int 4',
        int5: 'Int 5',
        string1: 'String 1',
        string2: 'String 2',
        string3: 'String 3',
        string4: 'String 4',
        string5: 'Dok.',
      },
      performanceTableColumns: {
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Rapportnivå',
        sortOrder: 'Sorteringsordning',
        isIndex: 'Är index',
        currentHolding: 'Nuvarande innehav',
        contactGuid: 'Kontakt-GUID',
        accountId: 'Kontonummer',
        portfolioId: 'Portfölj-ID',
        portfolioName: 'Portföljnamn',
        title: 'Tillgångsklass',
        securitySymbol: 'Symbol',
        isin: 'ISIN',
        sfdr: 'SFDR',
        portfolioBaseTypeCode: 'Bas-kod',
        securityTypeCode: 'Säkerhets-kod',
        allocation: 'Fördelning',
        costBasis: 'Kostnadsgrund',
        fifoCost: 'FIFO-kostnad',
        indexWeight: 'Strategisk vikt',
        lowerLimit: 'Nedre gräns',
        upperLimit: 'Övre gräns',
        movement: 'Insättning/uttag',
        quantity: 'Antal',
        unrealizedGainLoss: 'Orealiserad vinst/förlust',
        itdAnnualizedTwr: 'TWR sedan start',
        itdFromDate: 'Startdatum',
        oneYearTwr: '1 års TWR',
        oneYearTotalGain: '1 års totalvinst',
        oneYearFromDate: '1 års datum',
        threeYearTotalGain: '3 års totalvinst',
        threeYearAnnualizedTwr: '3 års TWR',
        threeYearFromDate: '3 års datum',
        fiveYearAnnualizedTwr: '5 års TWR',
        fiveYearTotalGain: '5 års totalvinst',
        fiveYearFromDate: '5 års datum',
        ytdTotalGain: 'Totalvinst YTD',
        ytdTwr: 'TWR YTD',
        ytdFromDate: 'YTD-datum',
        mtdTotalGain: 'Avkastning denna månad',
        mtdTwr: 'TWR MTD',
        mtdFromDate: 'MTD-datum',
        periodEndMarketValue: 'Marknadsvärde',
        periodEndRelativeMarketValue: 'Relativt MKV',
        periodEndTotalGain: 'Totalvinst',
        periodEndWeight: 'Portföljevikt',
        periodEndDate: 'Slutdatum',
        sinceInceptionTotalGain: 'Totalvinst sedan start',
        sinceInceptionAnnualizedTwr: 'Årlig TWR sedan start',
        sinceInceptionDate: 'Startdatum',
        thruDate: 'Genom datum',
        date1: 'Date 1',
        date2: 'Date 2',
        date3: 'Date 3',
        date4: 'Date 4',
        date5: 'Date 5',
        double1: 'Double 1',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'double 4',
        double5: 'Double 5',
        int1: 'Int 1',
        int2: 'Int 2',
        int3: 'Int 3',
        int4: 'Int 4',
        int5: 'Int 5',
        string1: 'String 1',
        string2: 'String 2',
        string3: 'String 3',
        string4: 'String 4',
        string5: 'Dok.',
      },
      performanceTableTwoColumns: {
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Rapportnivå',
        sortOrder: 'Sorteringsordning',
        isIndex: 'Är index',
        currentHolding: 'Nuvarande innehav',
        contactGuid: 'Kontakt-GUID',
        accountId: 'Kontonummer',
        portfolioId: 'Portfölj-ID',
        portfolioName: 'Portföljnamn',
        title: 'Tillgångsklass',
        securitySymbol: 'Symbol',
        isin: 'ISIN',
        sfdr: 'SFDR',
        portfolioBaseTypeCode: 'Bas-kod',
        securityTypeCode: 'Säkerhets-kod',
        allocation: 'Fördelning',
        costBasis: 'Kostnadsgrund',
        fifoCost: 'FIFO-kostnad',
        indexWeight: 'Strategisk vikt',
        lowerLimit: 'Nedre gräns',
        upperLimit: 'Övre gräns',
        movement: 'Insättning/uttag',
        quantity: 'Antal',
        unrealizedGainLoss: 'Orealiserad vinst/förlust',
        itdAnnualizedTwr: 'TWR sedan start',
        itdFromDate: 'Startdatum',
        oneYearTwr: '1 års TWR',
        oneYearTotalGain: '1 års totalvinst',
        oneYearFromDate: '1 års datum',
        threeYearTotalGain: '3 års totalvinst',
        threeYearAnnualizedTwr: '3 års TWR',
        threeYearFromDate: '3 års datum',
        fiveYearAnnualizedTwr: '5 års TWR',
        fiveYearTotalGain: '5 års totalvinst',
        fiveYearFromDate: '5 års datum',
        ytdTotalGain: 'Totalvinst YTD',
        ytdTwr: 'TWR YTD',
        ytdFromDate: 'YTD-datum',
        mtdTotalGain: 'Avkastning denna månad',
        mtdTwr: 'TWR MTD',
        mtdFromDate: 'MTD-datum',
        periodEndMarketValue: 'Marknadsvärde',
        periodEndRelativeMarketValue: 'Relativt MKV',
        periodEndTotalGain: 'Totalvinst',
        periodEndWeight: 'Portföljevikt',
        periodEndDate: 'Slutdatum',
        sinceInceptionTotalGain: 'Totalvinst sedan start',
        sinceInceptionAnnualizedTwr: 'Årlig TWR sedan start',
        sinceInceptionDate: 'Startdatum',
        thruDate: 'Genom datum',
        date1: 'Date 1',
        date2: 'Date 2',
        date3: 'Date 3',
        date4: 'Date 4',
        date5: 'Date 5',
        double1: 'Double 1',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'double 4',
        double5: 'Double 5',
        int1: 'Int 1',
        int2: 'Int 2',
        int3: 'Int 3',
        int4: 'Int 4',
        int5: 'Int 5',
        string1: 'String 1',
        string2: 'String 2',
        string3: 'String 3',
        string4: 'String 4',
        string5: 'Dok.',
      },
      transactionsTableColumns: {
        accountId: 'Kontonummer',
        portfolioId: 'Portfölj-ID',
        portfolioName: 'Portföljnamn',
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Report Level',
        sortOrder: 'Sorteringsordning',
        isHighlighted: 'Is Highlighted',
        contactGuid: 'Kontakt-GUID',
        title: 'Fond',
        status: ' ',
        type: 'Typ',
        transactionDate: 'Transaktionsdatum',
        expectedSettlementDate: 'Förväntat likviddatum',
        amount: 'Belopp',
        costPrice: 'Kostpris',
        quantity: 'Antal',
        isin: 'ISIN',
        securityTypeCode: 'Säkerhets-kod',
        date1: 'Date 1',
        date2: 'Date 2',
        date3: 'Date 3',
        date4: 'Date 4',
        date5: 'Date 5',
        double1: 'Double 1',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'Double 4',
        double5: 'Double 5',
        int1: 'Integer 1',
        int2: 'Integer 2',
        int3: 'Integer 3',
        int4: 'Integer 4',
        int5: 'Integer 5',
        string1: 'String 1',
        string2: 'String 2',
        string3: 'String 3',
        string4: 'String 4',
        string5: 'String 5',
      },
      transactionsTableData: {
        type: {
          1: 'Type 1',
          2: 'Type 2',
        },
        status: {
          1: 'Status 1',
          2: 'Status 2',
        },
      },
      indexTableColumns: {
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Rapportnivå',
        sortOrder: 'Sorteringsordning',
        indexGroup: 'Indexgrupp',
        title: 'Indexmonitor, avkastning i NOK',
        annualTwr: 'Hittills i år',
        oneYearTwr: 'Senaste året',
        threeYearTwr: 'Senaste 3 åren',
        fiveYearTwr: 'Senaste 5 åren',
        yield: 'Avkastning',
        lastPrice: 'Kurs',
        lastPriceDate: 'Senaste kursdata',
        date1: 'Date 1',
        date2: 'Date 2',
        double1: 'Double 1',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'Double 4',
        double5: 'Double 5',
        int1: 'Int 1',
        int2: 'Int 2',
        string1: 'String 1',
        string2: 'String 2',
      },
    },
    errors: {
      settingsError:
        'Det uppstod ett fel. Gå tillbaka till hemsidan och försök igen. ({0})',
      invalidStatus:
        'Det uppstod ett fel. Gå tillbaka till hemsidan och försök igen.',
      shouldNotRedirect:
        'Vi kunde dessvärre inte autentisera dig. Gå tillbaka till hemsidan och försök att logga in igen.',
      invalidAuth:
        'Vi kunde dessvärre inte autentisera dig. Gå tillbaka till hemsidan och försök att logga in igen.',
      invalidAction:
        'Denna handlingen är dessvärre inte tillåten. Gå tillbaka till hemsidan och försök igen.',
      invalidReturnUrl:
        'Vi kunde dessvärre inte autentisera dig. Gå tillbaka till hemsidan och försök att logga in igen.',
      getAccessTokenError:
        'Vi kunde dessvärre inte autentisera dig. Gå tillbaka till hemsidan och försök att logga in igen.',
      silentAuthError:
        'Vi kunde dessvärre inte autentisera dig. Gå tillbaka till hemsidan och försök att logga in igen.',
      silentTokenError:
        'Det uppstod dessvärre ett fel. Vänligen uppdatera sidan eller logga in på nytt.',
    },
  },
};
