import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { Navigate, useParams } from 'react-router-dom';
import { setLoadingMessage } from '../../../app/slices/loadingMessageSlice';
import { Loading, setLoading } from '../../../app/slices/loadingSlice';
import { getAxiosConfig } from '../../../helpers/utils';
import envVars from '../../../resources/envVars';
import authService from '../../api-authorization/AuthorizeService';
import DocumentPreview from './DocumentPreview';
import { setPreviewProps } from '../documents/documentsSlice';
import { useTranslations } from '../../../hooks/useTranslations';

const Document = () => {
  const dispatch = useAppDispatch();

  const { documentGuid } = useParams();

  const API_BASE_URI = envVars.API_BASE_URI;

  const [isDocumentFetchedError, setIsDocumentFetchedError] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<boolean | null>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const [isFetchFinished, setIsFetchFinished] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setShowError(true);
    }
  }, []);

  const { t } = useTranslations();
  const loadingDocumentPreviewMessage = t(
    `construo.documents.loadingDocumentPreview`,
  );

  useEffect(() => {
    (async () => {
      dispatch(setLoading(Loading.Show));
      dispatch(setLoadingMessage(loadingDocumentPreviewMessage));
      const token = await authService.getAccessToken();
      const config = getAxiosConfig(token, 'blob');
      const url =
        API_BASE_URI +
        '/user/documents/CustomerPortal/' +
        documentGuid +
        '/download';

      await axios
        .get(url, config)
        .then((response: any) => response.data)
        .then((blob: any) => {
          dispatch(setLoading(Loading.Hide));
          dispatch(setLoadingMessage(null));
          const newBlob = new Blob([blob], { type: blob.type });
          const fileURL = window.URL.createObjectURL(newBlob);
          dispatch(
            setPreviewProps({
              docType: blob.type,
              url: fileURL,
            }),
          );
        })
        .catch(error => {
          // Hide and reset loading msg
          dispatch(setLoading(Loading.Hide));
          dispatch(setLoadingMessage(null));
          // Show error message if getting document fails
          setIsDocumentFetchedError(true);
          setErrorMessage(error.toJSON().message);
          // And scroll to top so that alert is visible
          window.scrollTo(0, 0);
        })
        .finally(() => {
          setIsFetchFinished(true);
        });
    })();
  }, [dispatch, API_BASE_URI, documentGuid, loadingDocumentPreviewMessage]);

  if (isFetchFinished) {
    if (isDocumentFetchedError) {
      return (
        <Navigate
          to={`/resource-not-found?type=document&guid=${documentGuid}${
            showError ? `&error=${errorMessage}` : ``
          }`}
        />
      );
    } else {
      return <DocumentPreview guid={documentGuid!} />;
    }
  } else {
    return null;
  }
};

export default Document;
