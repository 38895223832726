import React, { useRef } from 'react';
import { Form } from '@formio/react';
import { default as flatpickrMessages } from 'flatpickr/dist/l10n';
import {
  daFormioMessages,
  enFormioMessages,
  fiFormioMessages,
  nbFormioMessages,
  nnFormioMessages,
  noFormioMessages,
  svFormioMessages,
} from '../../messages/formio';
import envVars from '../../resources/envVars';
import {
  DigitalIdentityProps,
  selectDigitalIdentity,
} from '../signatures/signaturesSlice';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectUser } from '../api-authorization/userSlice';

export interface FormioFormProps {
  form: any;
  submission: any;
  onSubmit: (submission: any) => void;
  onChange: (submission: any, schema?: any, event?: any) => void;
  languageCode?: any;
  readOnly?: any;
  isRequiredAuthenticationBtn?: boolean | null;
  onFormReady?: (formInstance: any) => void;
}

const FormioForm = ({
  form,
  submission,
  onSubmit,
  onChange,
  readOnly,
  languageCode,
  isRequiredAuthenticationBtn,
  onFormReady,
}: FormioFormProps) => {
  const userState = useAppSelector(selectUser);
  const digitalIdentity: DigitalIdentityProps | null = useAppSelector(
    selectDigitalIdentity,
  );
  const nin = digitalIdentity?.nin;
  const extendedSubmission = userState.isLoggedIn
    ? {
        ...submission,
        data: {
          ...submission?.data,
          prefill: {
            nin: nin,
          },
        },
      }
    : submission;

  const overwriteSubmitButtonMessage = (messages: any) => {
    messages = {
      ...messages,
      // Overwrites submit button text in MultiPage form by key
      submit: messages.submitForRequiredAuthentication,
      // Overwrites submit button text in SinglePage form by text content
      Submit: messages.submitForRequiredAuthentication,
    };
    return messages;
  };

  const getFormioMessages = (messages: any) => {
    return isRequiredAuthenticationBtn
      ? overwriteSubmitButtonMessage(messages)
      : messages;
  };

  const formioTranslations: any = {
    DA: {
      formio: {
        DA: getFormioMessages(daFormioMessages),
      },
      flatpickr: flatpickrMessages.da,
    },
    en: {
      formio: {
        en: getFormioMessages(enFormioMessages),
      },
      flatpickr: flatpickrMessages.en,
    },
    FI: {
      formio: {
        FI: getFormioMessages(fiFormioMessages),
      },
      flatpickr: flatpickrMessages.fi,
    },
    nb: {
      formio: {
        nb: getFormioMessages(nbFormioMessages),
      },
      flatpickr: flatpickrMessages.no,
    },
    nn: {
      formio: {
        nn: getFormioMessages(nnFormioMessages),
      },
      flatpickr: flatpickrMessages.no,
    },
    no: {
      formio: {
        no: getFormioMessages(noFormioMessages),
      },
      flatpickr: flatpickrMessages.no,
    },
    SV: {
      formio: {
        SV: getFormioMessages(svFormioMessages),
      },
      flatpickr: flatpickrMessages.sv,
    },
  };

  const API_BASE_URI = envVars.API_BASE_URI;
  const formioFormWrapperRef = useRef<null | HTMLDivElement>(null);

  const addComponent = (component: any) => {
    if (component.type === 'datetime') {
      component.customOptions = {
        locale: formioTranslations[languageCode].flatpickr,
      };
    }
    if (component.type === 'file') {
      const url = API_BASE_URI + component.url;
      component.url = url;
    }
    return component;
  };

  const onPrevPage = () => {
    formioFormWrapperRef.current?.scrollIntoView();
  };

  const onNextPage = () => {
    formioFormWrapperRef.current?.scrollIntoView();
  };

  const onError = (errors: any) => {
    errors[0].messages[0].self.element.scrollIntoView();
  };

  const onRender = () => {
    // Fix for Formio tooltips by Tippy.js
    formioFormWrapperRef.current
      ?.querySelectorAll('i[ref=tooltip]')
      .forEach((tooltip: any) => {
        // Prevent focusing on input elements which hides focused tooltip on touch devices
        tooltip.addEventListener('click', (e: any) => {
          e.stopPropagation();
          e.preventDefault();
        });

        // Fix layout issues on small screens
        tooltip._tippy.setProps({
          placement: 'auto',
        });
      });
  };

  return (
    <div className='formio-form-wrapper' ref={formioFormWrapperRef}>
      <Form
        form={form}
        submission={extendedSubmission}
        onSubmit={onSubmit}
        onChange={onChange}
        onPrevPage={onPrevPage}
        onNextPage={onNextPage}
        onError={onError}
        formReady={onFormReady}
        onRender={onRender}
        options={{
          readOnly: readOnly,
          language: languageCode,
          i18n: formioTranslations[languageCode]?.formio,
          hooks: {
            addComponent: addComponent,
          },
        }}
      />
    </div>
  );
};

export default FormioForm;
