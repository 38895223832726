import TableCellLink from '../components/pages/portfolios/TableCellLink';
import TableCellDate from '../components/pages/portfolios/TableCellDate';
import { TreeListColumnProps } from '@progress/kendo-react-treelist';

export type ConfigProps = {
  currencyAbbreviation: string;
  portfolios: {
    lastUpdatedInfoPlaceholders: string[];
    allocationsPieChart: {
      category: string;
      value: string;
      colors?: string[];
    };
    allocationsTable: {
      columns: TreeListColumnProps[];
      reportLevelDepth?: number;
    };
    holdingsTable: {
      columns: TreeListColumnProps[];
      reportLevelDepth?: number;
    };
    performanceGraph: {
      columns: {
        field: string;
        name: string;
      }[];
      horizontalLineColor: string;
      colors?: string[];
    };
    performanceTable: {
      columns: TreeListColumnProps[];
      reportLevelDepth?: number;
    };
    performanceTableTwo: {
      columns: TreeListColumnProps[];
      reportLevelDepth?: number;
    };
    transactionsTable: {
      columns: TreeListColumnProps[];
      reportLevelDepth?: number;
    };
    indexTable: {
      columns: TreeListColumnProps[];
      reportLevelDepth?: number;
    };
  };
};

export const config: ConfigProps = {
  currencyAbbreviation: 'NOK',
  portfolios: {
    lastUpdatedInfoPlaceholders: ['periodEndDate', 'thruDate'],

    allocationsPieChart: {
      category: 'title',
      value: 'periodEndMarketValue',
      // colors: ['purple', 'red', 'blue', 'green', 'orange', 'yellow'],
    },

    allocationsTable: {
      // if reportLevelDepth > 1, then first column - expandable: true, className: 'text-nowrap'
      reportLevelDepth: 1,
      columns: [
        {
          field: 'title',
          className: 'text-nowrap',
        },
        {
          field: 'periodEndMarketValue',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n0}',
        },
        {
          field: 'mtdTotalGain',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n0}',
        },
        {
          field: 'movement',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n0}',
        },
        {
          field: 'periodEndWeight',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n0}%',
        },
        {
          field: 'indexWeight',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n0}%',
        },
        {
          field: 'string1',
          headerClassName: 'text-end',
          className: 'text-end',
        },
      ],
    },
    holdingsTable: {
      reportLevelDepth: 2,
      columns: [
        {
          field: 'title',
          expandable: true,
          className: 'text-nowrap',
        },
        {
          field: 'string5',
          headerClassName: 'text-center',
          className: 'text-center',
          cell: TableCellLink,
        },
        {
          field: 'date2',
          headerClassName: 'text-end',
          className: 'text-end',
          cell: TableCellDate,
        },
        {
          field: 'quantity',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n2}',
        },
        {
          field: 'costBasis',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n0}',
        },
        {
          field: 'date1',
          headerClassName: 'text-end',
          className: 'text-end',
          cell: TableCellDate,
        },
        {
          field: 'periodEndMarketValue',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n0}',
        },
        {
          field: 'periodEndWeight',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n1}%',
        },
        {
          field: 'unrealizedGainLoss',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n0}',
        },
      ],
    },
    performanceGraph: {
      columns: [
        { field: 'timeSeriesDate', name: 'date' },
        { field: 'aggregateReturnIndexed', name: 'value' },
      ],
      horizontalLineColor: '#0d6efd',
      // colors: ['#0d6efd'],
    },
    performanceTable: {
      reportLevelDepth: 2,
      columns: [
        {
          field: 'title',
          expandable: true,
          className: 'text-nowrap',
        },
        {
          field: 'mtdTotalGain',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n0}',
        },
        {
          field: 'ytdTotalGain',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n0}',
        },
        {
          field: 'threeYearTotalGain',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n0}',
        },
        {
          field: 'fiveYearTotalGain',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n0}',
        },
        {
          field: 'sinceInceptionTotalGain',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n0}',
        },
      ],
    },

    performanceTableTwo: {
      reportLevelDepth: 2,
      columns: [
        {
          field: 'title',
          expandable: true,
          className: 'text-nowrap',
        },
        {
          field: 'mtdTwr',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n2}%',
        },
        {
          field: 'ytdTwr',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n2}%',
        },
        {
          field: 'threeYearAnnualizedTwr',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n2}%',
        },
        {
          field: 'fiveYearAnnualizedTwr',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n2}%',
        },
        {
          field: 'sinceInceptionAnnualizedTwr',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n2}%',
        },
      ],
    },

    transactionsTable: {
      reportLevelDepth: 1,
      columns: [
        {
          field: 'title',
          className: 'text-nowrap',
        },
        {
          field: 'amount',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n0}',
        },
        {
          field: 'quantity',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n2}',
        },
        {
          field: 'transactionDate',
          headerClassName: 'text-end',
          className: 'text-end',
          cell: TableCellDate,
        },
        {
          field: 'expectedSettlementDate',
          headerClassName: 'text-end',
          className: 'text-end',
          cell: TableCellDate,
        },
        {
          field: 'type',
          className: 'text-nowrap',
        },
        {
          field: 'status',
          className: 'text-nowrap',
        },
      ],
    },

    indexTable: {
      reportLevelDepth: 2,
      columns: [
        {
          field: 'title',
          expandable: true,
          className: 'text-nowrap',
        },
        {
          field: 'annualTwr',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n2}%',
        },
        {
          field: 'oneYearTwr',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n2}%',
        },
        {
          field: 'threeYearTwr',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n2}%',
        },
        {
          field: 'fiveYearTwr',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n2}%',
        },
        {
          field: 'yield',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n2}%',
        },
        {
          field: 'lastPrice',
          headerClassName: 'text-end',
          className: 'text-end',
          format: '{0:n2}',
        },
        {
          field: 'lastPriceDate',
          headerClassName: 'text-end',
          className: 'text-end',
          cell: TableCellDate,
        },
      ],
    },
  },
};
